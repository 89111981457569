<template>
  <div v-if="!angemeldeterMitarbeiter" class="container-fluid tile-container">
    <div class="row mt-70-px justify-content-center">
          <div class="col-12 col-md-8 col-lg-6 col-xl-4">
            <div class="tile-categorie-frame">
              <div class="tile-categorie">
                <div class="header text-center mt-4">
                  <span>Willkommen</span>
                </div>
                <div class="m-4">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span id="username" class="input-group-text">
                        <font-awesome-icon
                          icon="fa-duotone fa-user"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      name="username"
                      class="form-control"
                      :placeholder="$t('portal.username')"
                      aria-describedby="username"
                      autofocus
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      v-model="username"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span id="password" class="input-group-text">
                        <font-awesome-icon
                          icon="fa-duotone fa-key"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      type="password"
                      name="password"
                      class="form-control"
                      placeholder="Passwort"
                      aria-describedby="password"
                      autocomplete="off"
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      v-model="password"
                      @keyup.enter="handleSubmit"
                    />
                  </div>
                  <button class="btn btn-primary w-100 mb-3" @click="login">
                    Login
                  </button>
                  <div
                    class="hover-medium-blue d-inline-block"
                    style="cursor: pointer"
                    @click="navigateToResetPW"
                  >
                    Passwort vergessen?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";

import server from "../server";

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
    };
  },
  created() {
    window.addEventListener("keyup", (event) => {
      if (event.key === "Enter" && this.username && this.password) {
        this.login();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("keyup");
  },
  computed: {
    loginUrl() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/login/`;
      } else {
        return "/login/";
      }
    },
  },
  methods: {
    async login() {
      const params = new URLSearchParams();
      params.append("username", this.username);
      params.append("password", this.password);

      try {
        await axios.post(this.loginUrl, params);
        let startseite = this.$router.resolve({ name: "ERP Startseite" });
        window.location.href = startseite.href;
      } catch (error) {
        console.log(error);
      }
    },
    navigateToResetPW() {
      this.$router.push({
        name: "resetpassword",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-signin {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  padding: 15px;
}
</style>
